<template>
<!-- <div class="test"> -->
  <div class="c-genres c-moods-and-genres"
  :class="{'c-moods-and-genres--white': isWhiteBg}"
  ref="moodsGenresContainer">
    <div class="c-moods__content g-content">
      <ul class="columns-list" ref="columnsList">
        <li class="columns-list__item" v-for="(column, idx) of moodsList" :key="idx">
          <ul class="imgs-list">
            <li
              class="imgs-list__item item-hover--bottom"
              v-for="(img, idx2) of column.list"
              :key="idx2"
              @click="toInfoPage(img.name)"
            >
              <div class="box">
                <img class="box__img" src="@/assets/explore/moods_img.jpg" />
                <div class="box__overlay"></div>
                <p class="box__label">{{ img.name }}</p>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import {
  defineComponent, computed, ref, onMounted, onUnmounted,
  nextTick,
} from 'vue';
import { useRouter } from 'vue-router';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useSiteStore } from '@/store/site';
import { getTagGroupListAPI } from '@/api/getTagGroupList';

export default defineComponent({
  name: 'Moods',
  setup() {
    const router = useRouter();
    const siteStore = useSiteStore();
    const moods = ref([]);
    const columnsList = ref(null);
    const moodsGenresContainer = ref(null);
    const isWhiteBg = ref(false);

    const toInfoPage = (label) => {
      const formatLabel = label.toLowerCase().replace('/', '-').replace(/\s/g, '');

      router.push({
        name: 'explore-moods-info',
        params: {
          category: formatLabel,
        },
      });
    };

    const moodsList = computed(() => {
      if (siteStore.deviceType.includes('sm-pc')) {
        return moods.value.reduce(
          (arr, item, index) => {
            const remaining = index % 4;
            arr[remaining].list.push(item);
            return arr;
          },
          [{ list: [] }, { list: [] }, { list: [] }, { list: [] }],
        );
      }

      if (siteStore.deviceType.includes('tablet')) {
        return moods.value.reduce(
          (arr, item, index) => {
            const remaining = index % 3;
            arr[remaining].list.push(item);

            return arr;
          },
          [{ list: [] }, { list: [] }, { list: [] }],
        );
      }

      return moods.value.reduce(
        (arr, item, index) => {
          const remaining = index % 2;
          arr[remaining].list.push(item);
          return arr;
        },
        [{ list: [] }, { list: [] }],
      );
    });

    const bgTransition = () => {
      const offsetTop = moodsGenresContainer.value.getBoundingClientRect().top;
      if (offsetTop <= 0) {
        isWhiteBg.value = true;

        const scroller = document.querySelector('.ly-full-header-layout');
        scroller.removeEventListener('scroll', bgTransition);
      }
    };

    getTagGroupListAPI({ in: 'moods' }).then((response) => {
      const [, res] = response;
      moods.value = res.getTagGroupList.map((item) => ({
        name: item.name || item.enName,
      }));
    }).then(async () => {
      await nextTick();
      gsap.registerPlugin(ScrollTrigger);
      const scroller = document.querySelector('.ly-full-header-layout');

      // 轉成黑背景
      scroller.addEventListener('scroll', bgTransition);

      // 視差

      gsap.utils.toArray('.imgs-list__item').forEach((section) => {
        gsap.from(section.querySelectorAll('.box__img, .box__label, .box__overlay'), {
          scrollTrigger: {
            trigger: section,
            scroller,
          },
          y: 50,
          duration: 0.75,
          stagger: 0.25,
        });
      });
    });

    onMounted(() => {
      // ScrollTrigger.refresh();
      // gsapSet();

      // gsap.registerPlugin(ScrollTrigger);
      // gsap.utils.toArray('.imgs-list__item').forEach((section) => {
      //   console.log('section', section);
      //   gsap.from(section.querySelectorAll('.box__img, .box__label, .box__overlay'), {
      //     scrollTrigger: section,
      //     // autoAlpha: 1,
      //     y: 25,
      //     duration: 0.75,
      //     stagger: 0.25,
      //   });
      // });
    });
    onUnmounted(() => {
      // triggers.forEach((trigger) => {
      //   trigger.kill();
      // });
      // ScrollTrigger.clearMatchMedia();
    });

    return {
      columnsList,
      moodsList,
      toInfoPage,
      moodsGenresContainer,
      isWhiteBg,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/moodsAndGenres.scss';
</style>
