import graphqlRequest from '@/api/graphql';

const baseURL = process.env.VUE_APP_BASE_API;

console.log('baseURL', baseURL);

const request = graphqlRequest.create({
  baseURL,
  descriptor: false,
  auth: false,
});

export default request;
