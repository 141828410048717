<template>
  <div class="c-genres c-moods-and-genres"
  :class="{'c-moods-and-genres--white': isWhiteBg}"
  ref="moodsGenresContainer">
    <div class="c-genres__content g-content">
      <ul class="columns-list">
        <li class="columns-list__item" v-for="(column, idx) of genresList" :key="idx">
          <ul class="imgs-list">
            <li
              class="imgs-list__item item-hover--bottom"
              v-for="(img, idx2) of column.list"
              :key="idx2"
              @click="toInfoPage(img.name)"
            >
              <div class="box">
                <img class="box__img" src="@/assets/explore/genres_img.jpg" />

                <div class="box__overlay"></div>
                <p class="box__label">
                  {{ img.name }}
                </p>
              </div>
            </li>
          </ul>Ｆ
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {
  defineComponent, computed, ref, nextTick,
} from 'vue';
import { useRouter } from 'vue-router';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useSiteStore } from '@/store/site';
import { getTagGroupListAPI } from '@/api/getTagGroupList';

export default defineComponent({
  name: 'Genres',
  setup() {
    const siteStore = useSiteStore();
    const router = useRouter();
    const genres = ref([]);
    const moodsGenresContainer = ref(null);
    const isWhiteBg = ref(false);

    const toInfoPage = (label) => {
      // const formatLabel = label.toLowerCase().replace('/', '-').replace(/\s/g, '');

      router.push({
        name: 'explore-genres-info',
        params: {
          category: label,
        },
      });
    };

    const genresList = computed(() => {
      if (siteStore.deviceType.includes('sm-pc')) {
        return genres.value.reduce(
          (arr, item, index) => {
            const remaining = index % 4;
            arr[remaining].list.push(item);
            return arr;
          },
          [{ list: [] }, { list: [] }, { list: [] }, { list: [] }],
        );
      }

      if (siteStore.deviceType.includes('tablet')) {
        return genres.value.reduce(
          (arr, item, index) => {
            const remaining = index % 3;
            arr[remaining].list.push(item);

            return arr;
          },
          [{ list: [] }, { list: [] }, { list: [] }],
        );
      }

      return genres.value.reduce(
        (arr, item, index) => {
          const remaining = index % 2;
          arr[remaining].list.push(item);
          return arr;
        },
        [{ list: [] }, { list: [] }],
      );
    });

    const bgTransition = () => {
      const offsetTop = moodsGenresContainer.value.getBoundingClientRect().top;
      if (offsetTop <= 0) {
        isWhiteBg.value = true;

        const scroller = document.querySelector('.ly-full-header-layout');
        scroller.removeEventListener('scroll', bgTransition);
      }
    };

    getTagGroupListAPI({ in: 'musicStyles' }).then((response) => {
      const [, res] = response;

      genres.value = res.getTagGroupList.map((item) => ({
        name: item.name || item.enName,
      }));
    }).then(async () => {
      await nextTick();
      const scroller = document.querySelector('.ly-full-header-layout');

      // 轉成黑背景
      scroller.addEventListener('scroll', bgTransition);

      // 視差
      gsap.registerPlugin(ScrollTrigger);
      gsap.utils.toArray('.imgs-list__item').forEach((section) => {
        gsap.from(section.querySelectorAll('.box__img, .box__label, .box__overlay'), {
          scrollTrigger: {
            trigger: section,
            scroller,
          },
          y: 50,
          duration: 0.75,
          stagger: 0.25,
        });
      });
    });

    return {
      genresList,
      toInfoPage,
      moodsGenresContainer,
      isWhiteBg,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/moodsAndGenres.scss';
</style>
