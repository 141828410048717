import themesImg from '@/assets/exploreBanner/themes_banner.jpg';
import moodsImg from '@/assets/exploreBanner/moods_banner.jpg';
import genresImg from '@/assets/exploreBanner/genres_banner.jpg';
import albumsImg from '@/assets/exploreBanner/albums_banner.jpg';
import playlistsImg from '@/assets/exploreBanner/playlists_banner.jpg';
import labelsImg from '@/assets/exploreBanner/labels_banner.jpg';

export const bannerData = {
  themes: {
    bgImagePath: themesImg,
    desc: '依主題探索音樂－發掘我們精心策劃的主題，為您的想像帶來無限靈感。',
  },
  moods: {
    bgImagePath: moodsImg,
    desc: '依氣氛探索音樂－從英雄電影配樂到誘人的廣告活動，氣氛播放列表都能滿足您的情感音符。',
  },
  genres: {
    bgImagePath: genresImg,
    desc: '依曲風探索音樂－無倫是想振奮精神或放鬆身心，您可以在曲風找到各種符合的音樂。',
  },
  albums: {
    bgImagePath: albumsImg,
    desc: '依專輯探索音樂－為您收錄上萬張不同類型的音樂家、經典到流行的各式專輯。',
  },

  playlists: {
    bgImagePath: playlistsImg,
    desc: '依歌單探索音樂－每個歌單都經過我們悉心的設計，以打造最符合心境的旋律列表。',
  },
  labels: {
    bgImagePath: labelsImg,
    desc: '依品牌探索音樂－從世界各地最有才華的唱片公司找到專輯與曲目。',
  },
};

export const mobileGenres = [
  {
    list: [
      { label: '原音樂', labelEn: 'ACOUSTIC' },
      { label: '古典', labelEn: 'CLASSICAL' },
      { label: '電子舞曲', labelEn: 'DANCE / ELECTRONICA' },
      { label: '迴響貝斯', labelEn: 'DUBSTEP' },
      { label: '電子流行曲', labelEn: 'ELECTRO POP' },
      { label: '放克', labelEn: 'FUNK' },
      { label: '浩室', labelEn: 'HOUSE' },
      { label: '爵士', labelEn: 'JAZZ' },
      { label: '拉丁', labelEn: 'LATIN' },
      { label: '金屬', labelEn: 'METAL' },
      { label: '流行曲', labelEn: 'POP' },
      { label: '騷靈', labelEn: 'SOUL' },
    ],
  },
  {
    list: [
      { label: '藍調', labelEn: 'BLUES' },
      { label: '鄉村音樂', labelEn: 'COUNTRY' },
      { label: '持續音', labelEn: 'DRONES' },
      { label: '輕音樂', labelEn: 'EASY LISTENING' },
      { label: '民歌', labelEn: 'FOLK' },
      { label: '嘻哈', labelEn: 'HIP HOP' },
      { label: '獨立', labelEn: '/ 非主流搖滾 INDIE / ALTERNATIVE ROCK' },
      { label: '俗氣', labelEn: 'KITSCH' },
      { label: '進行曲', labelEn: 'MARCH' },
      { label: '歌劇', labelEn: 'OPERA' },
      { label: '搖滾', labelEn: 'ROCK' },
      { label: '都市', labelEn: '/ 節奏藍調 URBAN / R&B' },
    ],
  },
];

export const genres = [

  { label: '原音樂', labelEn: 'ACOUSTIC' },
  { label: '藍調', labelEn: 'BLUES' },
  { label: '古典', labelEn: 'CLASSICAL' },
  { label: '鄉村音樂', labelEn: 'COUNTRY' },
  { label: '電子舞曲', labelEn: 'DANCE / ELECTRONICA' },
  { label: '持續音', labelEn: 'DRONES' },

  { label: '迴響貝斯', labelEn: 'DUBSTEP' },
  { label: '輕音樂', labelEn: 'EASY LISTENING' },
  { label: '電子流行曲', labelEn: 'ELECTRO POP' },
  { label: '民歌', labelEn: 'FOLK' },
  { label: '放克', labelEn: 'FUNK' },
  { label: '嘻哈', labelEn: 'HIP HOP' },

  { label: '浩室', labelEn: 'HOUSE' },
  { label: '獨立/ 非主流搖滾', labelEn: 'INDIE / ALTERNATIVE ROCK' },
  { label: '爵士', labelEn: 'JAZZ' },
  { label: '俗氣', labelEn: 'KITSCH' },
  { label: '拉丁', labelEn: 'LATIN' },
  { label: '進行曲', labelEn: 'MARCH' },

  { label: '金屬', labelEn: 'METAL' },
  { label: '歌劇', labelEn: 'OPERA' },
  { label: '流行曲', labelEn: 'POP' },
  { label: '搖滾', labelEn: 'ROCK' },
  { label: '騷靈', labelEn: 'SOUL' },
  { label: '都市', labelEn: '/ 節奏藍調 URBAN / R&B' },

];

export const moods = [
  { label: '激進', labelEn: 'AGGRESSIVE' },
  { label: '氛圍', labelEn: 'ATMOSPHERIC' },
  { label: '優美', labelEn: 'BEAUTY' },
  { label: '漸強', labelEn: 'BUILDING' },
  { label: '戲劇性', labelEn: 'DRAMATIC' },
  { label: '煽情', labelEn: 'EMOTIONAL' },
  { label: '力量', labelEn: 'ENERGY' },
  { label: '史詩式', labelEn: 'EPIC' },
  { label: '夢幻', labelEn: 'FANTASY' },
  { label: '趣味', labelEn: 'FUN' },
  { label: '開心', labelEn: '正面 HAPPY / POSITIVE' },
  { label: '英勇', labelEn: 'HEROIC' },
  { label: '幽默', labelEn: 'HUMOUR' },
  { label: '悠閒', labelEn: 'LAID BACK / CHILLED' },
  { label: '魔幻', labelEn: 'MAGICAL' },
  { label: '簡約', labelEn: 'MINIMAL' },
  { label: '勵志', labelEn: 'MOTIVATIONAL' },
  { label: '詭怪', labelEn: 'QUIRKY' },
  { label: '浪漫', labelEn: 'ROMANTIC' },
  { label: '悲傷', labelEn: 'SAD' },
  { label: '性感', labelEn: 'SEXY' },
  { label: '懸疑', labelEn: 'SUSPENSE' },
  { label: '振奮', labelEn: 'UPLIFTING' },
  { label: '工作', labelEn: 'WORKING' },
];
