import axios from 'axios';
import { isBoolean, isArray } from '../utils/lodash';

const handleAxios = (baseURL) => axios.create({
  baseURL: baseURL || './',
  timeout: 20000,
  validateStatus: (status) => status >= 200 && status < 500,
});

const handleInterceptors = (target, interceptors) => {
  if (interceptors && target && typeof target?.use === 'function') {
    const arr = isArray(interceptors) ? interceptors : [interceptors];
    arr.forEach((v) => {
      if (typeof v === 'function') target.use(v);
    });
  }
};

export const basicRequest = (
  {
    baseURL = '',
    urlPrefix = '',
    version = '',
    auth = false,
    tokenHeader = 'Bearer',
    getToken = null,
    errorHandler = null,
  } = {},
  { interceptorsRequest = null, interceptorsResponse = null } = {},
) => {
  const request = handleAxios(baseURL);
  handleInterceptors(request.interceptors.request, interceptorsRequest);
  handleInterceptors(request.interceptors.response, interceptorsResponse);

  return async ({
    url = '',
    method = 'GET',
    headers = {},
    version: specifyVersion = '',
    urlPrefix: specifyUrlPrefix = '',
    baseURL: specifyBaseURL = '',
    auth: specifyAuth,
    ...rest
  }) => {
    const formatUrl = specifyBaseURL
      ? `${specifyBaseURL}${url}`
      : `${specifyUrlPrefix || urlPrefix}${specifyVersion || version}${url}`;
    try {
      const response = await request({
        url: formatUrl,
        method,
        headers: {
          ...((isBoolean(specifyAuth) ? specifyAuth : auth) && typeof getToken === 'function' && getToken()
            ? { Authorization: tokenHeader ? `${tokenHeader} ${getToken()}` : `${getToken()}` }
            : {}),
          ...headers,
        },
        ...rest,
      });
      if (typeof errorHandler === 'function') errorHandler(response);
      if (response?.status === 200) {
        return [null, response.data];
      }
      return [response?.status >= 400 ? new Error(response.data.message) : null, response];
    } catch (error) {
      return [error, null];
    }
  };
};

function restfulRequest() {}
restfulRequest.create = ({
  baseURL,
  urlPrefix = '',
  version = '',

  // 是否需要帶 token
  auth = true,
  // 獲取 token 的方法
  tokenHeader = 'Bearer',
  getToken = null,
  // 錯誤處理
  errorHandler = null,
} = {}) => basicRequest({
  baseURL,
  urlPrefix,
  version,
  auth,
  tokenHeader,
  getToken,
  errorHandler,
});

export default restfulRequest;
