<template>
  <div class="c-theme-box">
    <div
      class="c-theme-box__content"
      :style="{
        width: size.w,
        height: size.h,

        'background-image': `url(${data.imgPath})`,
      }"
      @click="toInfoPage"
    >
      <div class="box-mask">
        <div class="text">
          <p class="text__desc">
            {{ data.desc }}
          </p>
          <p class="text__learn-more">了解更多</p>
        </div>
      </div>
    </div>
    <p class="title">{{ data.title }}</p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'ThemeBox',
  props: {
    data: {
      type: Object,
      default: () => ({
        imgPath: '',
        title: 'Gaming Music',
        desc: 'Blast your project into another dimension with our BAFTA winning composers, action-packed albums and console-centric playlists! Explore below to…',
      }),
    },
    size: {
      type: Object,
      default: () => ({
        w: '640px',
        h: '340px',
      }),
    },
    imgPath: {},
  },
  setup(props) {
    const router = useRouter();
    const siteStore = useSiteStore();

    const toInfoPage = () => {
      // TOFIX-0217: info page 接好 api後，移除這行
      siteStore.setBackgroundImage(props.data.imgPath);

      router.push({
        name: 'explore-themes-info',
        params: {
          category: props.data.title,
        },
      });
    };

    return { toInfoPage };
  },
});
</script>
<style lang="scss" scoped>
.c-theme-box {
  width: 100%;

  &__content {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('~@/assets/explore/themes_img.png');
    cursor: pointer;

    .box-mask {
      opacity: 1;
    }

    &:hover {
      .box-mask {
        opacity: 1;
      }
    }
  }
}

.theme-image {
  width: 100%;
}

.box-mask {
  @include position(tl, 0, 0);
  @include flex(center);
  width: 100%;
  height: 100%;
  background-color: rgba($c-black, 0.5);
  transition: 0.3s;
  opacity: 0;

  &::after {
    content: '';
    @extend %bg-gradient-90deg;
    display: inline-block;
    width: 100%;
    height: 5px;
    @include position(bl, 0, 0);
  }
}
.text {
  width: 60%;

  &__desc {
    @include font-style($c-white, 16, 400, 0px);
  }

  &__learn-more {
    @include font-style($c-main, 12, 400, 0.8px);
    @include padding(0 0 0 15px);
    position: relative;
    margin-top: 16px;

    &::before {
      content: '';
      @include circle(6px);
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
      display: inline-block;
      background-color: $c-main;
    }
  }
}

.title {
  display: none;
}

@media screen and (min-width: $tablet) {
  .c-theme-box {
    display: inline-block;
    width: auto;
  }
  .title {
    display: block;
    margin-top: 10px;
    @include font-style($c-white, 30, bold, 1.5px);
  }

  .c-theme-box {
    &__content {
      .box-mask {
        opacity: 0;
      }

      &:hover {
        .box-mask {
          opacity: 1;
        }
      }
    }
  }
}
</style>
