<template>
  <div class="v-explore">
    <div class="v-themes__content">
      <banner />
      <div class="main" :class="`main--grid`">
        <div class="main__content">
          <component :is="route.params.type" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
} from 'vue';
import { useRoute } from 'vue-router';
import banner from '@/components/Explore/Banner.vue';
import themes from '@/components/Explore/Themes.vue';
import moods from '@/components/Explore/Moods.vue';
import genres from '@/components/Explore/Genres.vue';
import albums from '@/components/Explore/Albums.vue';
import playlists from '@/components/Explore/Playlists.vue';
import labels from '@/components/Explore/Labels.vue';

export default defineComponent({
  name: 'Explore',
  components: {
    banner,
    themes,
    moods,
    genres,
    albums,
    playlists,
    labels,
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
});
</script>
<style lang="scss" scoped>
.v-themes {
  &__content {
  }
}

</style>
