<template>
  <div class="c-tabs">
    <ul class="tab-list">
      <li
        class="tab-list__item"
        v-for="tab of bannerTabsConfig"
        :key="tab.key"
        @click="toPage(tab.key)"
        @mouseenter="hoverEffect('exploreActive', tab.key)"
        @mouseleave="hoverEffect('explore', tab.key)"
      >
        <component
          :is="`${tab.key}Icon`"
          mainType="explore"
          :colorType="colorType"
          :currIcon="currIcon"
          :iconKey="tab.key"
          size="30"
        />

        <span class="tab-list__item__text" :class="{ 'tab-list__item__text--active': tab.key === route.params.type }">
          {{ tab.label }}
        </span>

        <img
          class="tab-list__item__decorate-img"
          :class="{ 'tab-list__item__decorate-img--active': tab.key === route.params.type }"
          src="@/assets/icon/highlight_3.png"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import bannerTabsConfig from '@/config/bannerTabsConfig';
// import IconComp from '@/components/Explore/Icon/index.vue';
import ThemesIcon from '@/components/Explore/Icon/ThemesIcon.vue';
import MoodsIcon from '@/components/Explore/Icon/MoodsIcon.vue';
import GenresIcon from '@/components/Explore/Icon/GenresIcon.vue';
import AlbumsIcon from '@/components/Explore/Icon/AlbumsIcon.vue';
import PlaylistsIcon from '@/components/Explore/Icon/PlaylistsIcon.vue';
import LabelsIcon from '@/components/Explore/Icon/LabelsIcon.vue';

export default defineComponent({
  name: 'Tabs',
  components: {
    // IconComp,
    ThemesIcon,
    MoodsIcon,
    GenresIcon,
    AlbumsIcon,
    PlaylistsIcon,
    LabelsIcon,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const colorType = ref('explore');
    const currIcon = ref('');
    const hoverEffect = (type, icon) => {
      colorType.value = type;
      currIcon.value = icon;
    };

    const toPage = (type) => {
      router.push({
        name: 'explore-type',
        params: {
          type,
        },
      });
    };

    return {
      bannerTabsConfig,
      route,
      toPage,
      colorType,
      currIcon,
      hoverEffect,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-tabs {
  display: inline-block;
}

.tab-list {
  @include flex();

  &__item {
    @include inline-flex(center, center, column);
    cursor: pointer;
    position: relative;

    & + & {
      margin-left: 15px;
    }

    &:hover {
      .tab-list__item__text {
        color: $c-white;
      }

      .tab-list__item__decorate-img {
        display: inline-block;
      }
    }

    &__icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-size: 18px 18px;
    }

    &__text {
      @include font-style($c-assist4, 18, 400, 1px);
      margin-left: 0px;
      position: relative;
      z-index: 10;

      &--active {
        color: $c-white;
      }
    }

    &__decorate-img {
      @include position(br, 0px, -5px);
      display: none;

      &--active {
        display: inline-block;
      }
    }
  }
}

@media screen and (min-width: $mobile) {
  .tab-list {
    &__item {
      @include inline-flex();

      &__text {
        margin-left: 5px;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .tab-list {
    &__item {
      @include inline-flex();

      & + & {
        margin-left: 50px;
      }

      &__text {
        @include font-size(28);
        margin-left: 10px;
      }
    }
  }
}
</style>
