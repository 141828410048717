import request from '@/api';
import getTagGroupList from '@/api/getTagGroupList.gql';

export const getTagGroupListAPI = async (payload) => {
  const res = await request(getTagGroupList, payload);

  return res;
};

export const a = 0;
