<template>
  <div class="c-labels-box item-hover--bottom" @click="toInfoPage">
    <div class="label-box">
      <img class="label-box__img" :src="data.imgPath" />
    </div>
    <p class="label-title">{{ data.title }}</p>
    <p class="label-desc">{{ data.desc }}</p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'LabelsBox',
  props: {
    data: {
      type: Object,
      default: () => ({
        imgPath: '',
        title: 'A REMARKABLE IDEA',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      }),
    },
  },
  setup(props) {
    const router = useRouter();

    const toInfoPage = () => {
      router.push({
        name: 'explore-labels-info',
        params: {
          category: props.data.title,
        },
      });
    };

    return { toInfoPage };
  },
});
</script>
<style lang="scss" scoped>
.c-labels-box {
  @include padding(24px 26px);
  border-radius: 5px;
  background-color: $c-white;
}
.label-box {
  @include flex(flex-start);
  height: 80px;

  &__img {
    max-height: 80px;
    vertical-align: bottom;
  }
}

.label-title {
  margin-top: 20px;
  @include font-style($c-black, 20, 800, 1px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.label-desc {
  margin-top: 10px;
  @include font-style($c-assist6, 14, 400, 1px);
}
</style>
