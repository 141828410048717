import img1 from '@/assets/explore/themes_img.png';
import img2 from '@/assets/explore/themes_img_2.png';
import img3 from '@/assets/explore/themes_img_3.png';
import img4 from '@/assets/explore/themes_img_4.png';
import img5 from '@/assets/explore/themes_img_5.png';
import img6 from '@/assets/explore/themes_img_6.png';
import img7 from '@/assets/explore/themes_img_7.png';
import img8 from '@/assets/explore/themes_img_8.png';

export default {
  1: {
    title: 'Gaming Music',
    imgPath: img1,
    desc: 'Blast your project into another dimension with our BAFTA winning composers, action-packed albums and console-centric playlists! Explore below to…',
  },
  2: {
    title: 'Earth Day',
    imgPath: img2,
    desc: 'Blast your project into another dimension with our BAFTA winning composers, action-packed albums and console-centric playlists! Explore below to…',
  },
  3: {
    title: '芭蕾',
    imgPath: img3,
    desc: 'Blast your project into another dimension with our BAFTA winning composers, action-packed albums and console-centric playlists! Explore below to…',
  },
  4: {
    title: '預告片',
    imgPath: img4,
    desc: 'Blast your project into another dimension with our BAFTA winning composers, action-packed albums and console-centric playlists! Explore below to…',
  },
  5: {
    title: 'Re Cover',
    imgPath: img5,
    desc: 'Blast your project into another dimension with our BAFTA winning composers, action-packed albums and console-centric playlists! Explore below to…',
  },
  6: {
    title: 'Sure Stories',
    imgPath: img6,
    desc: 'Blast your project into another dimension with our BAFTA winning composers, action-packed albums and console-centric playlists! Explore below to…',
  },
  7: {
    title: 'Summer',
    imgPath: img7,
    desc: 'Blast your project into another dimension with our BAFTA winning composers, action-packed albums and console-centric playlists! Explore below to…',
  },
  8: {
    title: '春天',
    imgPath: img8,
    desc: 'Blast your project into another dimension with our BAFTA winning composers, action-packed albums and console-centric playlists! Explore below to…',
  },
};
