<template>
  <div class="c-banner">
    <div
      class="c-banner__content"
      :style="{
        'background-image': `url('${bannerData[route.params.type].bgImagePath}')`,
      }"
    >
      <div class="main-content g-content">
        <h2 class="title">探索音樂</h2>
        <div class="list">
          <Tabs />
        </div>
        <div class="desc">
          {{ bannerData[route.params.type].desc }}
        </div>
      </div>
    </div>
    <div class="desc-mobile">
      <div class="g-content">
        {{ bannerData[route.params.type].desc }}
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import Tabs from '@/components/Explore/Tabs.vue';
import { bannerData } from '@/config/exploreConfig';

export default defineComponent({
  name: 'Banner',
  components: {
    Tabs,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },

  setup() {
    const route = useRoute();

    return {
      bannerData,
      route,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-banner {
  width: 100%;
  position: relative;
  // box-shadow: 0 10px 10px $c-black;

  &::after {
    content: '';
    width: 100%;
    height: 10px;
    background: linear-gradient(0deg, transparent 30%, rgba(2,0,36,1) 100%);
    @include position(bl, 0px, 0);
  }

  &__content {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 220px;

  }
}

.main-content {
  height: 100%;
  @include flex(flex-end, center, column);
}

.title {
  @include font-style($c-white, 24, normal, 2.25px);
}

.list {
  margin-top: 38px;
}

.desc {
  display: none;
}

.desc-mobile {
  padding-top: 28px;
  padding-bottom: 65px;
  background-color: $c-black;
  text-align: center;
  @include font-style($c-white, 16, normal, 0.8px);
}

@media screen and (min-width: $tablet) {
  .main-content {
    height: 100%;
    @include flex(center, center, column);
  }
  .c-banner {

    &__content {
      height: 500px;
    }
  }

  .list {
    margin-top: 77px;

  }
  .title {
    @include font-size(45);
  }

  .desc {
    display: block;
    margin-top: 28px;
    @include font-style($c-white, 16, normal, 0.8px);
  }

  .desc-mobile {
    display: none;
  }
}
</style>
