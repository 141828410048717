<template>
  <div class="c-themes">
    <div class="g-content">
      <div class="c-themes__content">
        <div class="year-wrap">
          <mi-select v-model:value="yearValue" :options="mockOptions" label="Year" color="white" />
        </div>
        <div class="row row--a">
          <div class="theme-box theme-box--a-1">
            <theme-box :size="imageSize.large" :data="themesConfig['1']" />
          </div>
          <div class="theme-box theme-box--a-2">
            <theme-box :size="imageSize.small" :data="themesConfig['2']" />
          </div>
        </div>
        <div class="row row--b">
          <div class="theme-box theme-box--b-1">
            <theme-box :size="imageSize.large" :data="themesConfig['3']" />
          </div>
        </div>
        <div class="row row--c">
          <div class="theme-box theme-box--c-1">
            <theme-box :size="imageSize.small" :data="themesConfig['4']" />
          </div>
          <div class="theme-box theme-box--c-2">
            <theme-box :size="imageSize.large" :data="themesConfig['5']" />
          </div>
        </div>
        <div class="row row--d">
          <div class="theme-box theme-box--d-1">
            <theme-box :size="imageSize.large" :data="themesConfig['6']" />
          </div>
          <div class="theme-box theme-box--d-2">
            <theme-box :size="imageSize.small" :data="themesConfig['7']" />
          </div>
        </div>

        <div class="row row--e">
          <div class="theme-box theme-box--e-1">
            <theme-box :size="imageSize.large" :data="themesConfig['8']" />
          </div>
        </div>

      </div>
    </div>
    <div class="pagination-wrap">
        <mi-search-pagination :pager="pager" @handlePagination="handlePagination" isWhiteBg />
        </div>
  </div>
</template>
<script>
import {
  defineComponent, ref, reactive, computed, onMounted,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ThemeBox from '@/components/Explore/ThemeBox.vue';
// import MiPagination from '@/components/Global/MiPagination.vue';
import themesConfig from '@/config/themesConfig';
import MiSelect from '@/components/Global/Select/index.vue';
import { useSiteStore } from '@/store/site';
import MiSearchPagination from '@/components/Global/MiSearchPagination.vue';

export default defineComponent({
  name: 'Themes',
  components: {
    ThemeBox,
    // MiPagination,
    MiSelect,
    MiSearchPagination,
  },
  setup() {
    const siteStore = useSiteStore();
    const yearValue = ref('2021');
    const router = useRouter();
    const route = useRoute();

    const mockOptions = [
      {
        label: '2021',
        value: '2021',
      },
      {
        label: '2022',
        value: '2022',
      },
    ];

    const imageSize = computed(() => {
      console.log('siteStore.deviceType', siteStore.deviceType);

      if (siteStore.deviceType.includes('pc')) {
        return {
          large: {
            w: '640px',
            h: '360px',
          },
          small: {
            w: '480px',
            h: '270px',
          },
        };
      }

      if (siteStore.deviceType.includes('sm-pc')) {
        return {
          large: {
            w: '580px',
            h: '320px',
          },
          small: {
            w: '400px',
            h: '225px',
          },
        };
      }

      if (siteStore.deviceType.includes('tablet')) {
        return {
          large: {
            w: '500px',
            h: '280px',
          },
          small: {
            w: '375px',
            h: '210px',
          },
        };
      }

      if (siteStore.deviceType.includes('portrait')) {
        return {
          large: {
            w: '100%',
            h: '35vw',
          },
          small: {
            w: '100%',
            h: '35vw',
          },
        };
      }

      if (siteStore.deviceType.includes('mobile')) {
        return {
          large: {
            w: '100%',
            h: '29vw',
          },
          small: {
            w: '100%',
            h: '29vw',
          },
        };
      }

      return {
        large: {
          w: '100%',
          h: '188px',
        },
        small: {
          w: '100%',
          h: '188px',
        },
      };
    });

    const pager = reactive({
      pageIndex: route.query?.pageIndex || 1,
      totalPage: 2,
    });

    const handlePagination = (targetPage) => {
      pager.pageIndex = targetPage;

      router.push({
        name: 'explore-type',
        params: {
          type: 'themes',
        },
        query: {
          pageIndex: pager.pageIndex,
        },
      });
    };

    onMounted(() => {
      gsap.registerPlugin(ScrollTrigger);
      const scroller = document.querySelector('.ly-full-header-layout');

      gsap.utils.toArray('.c-themes__content').forEach((section) => {
        gsap.from(section.querySelectorAll('.row'), {
          scrollTrigger: {
            trigger: section,
            scroller,
          },
          y: 100,
          duration: 0.75,
          stagger: 0.25,
        });
      });
    });

    return {
      yearValue,
      imageSize,
      pager,
      themesConfig,
      mockOptions,
      handlePagination,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-themes {
  background-color: $c-black;
  padding-top: 0px;
  padding-bottom: 45px;

}
.year-wrap {
  width: 100%;
  @include flex(flex-end);
  margin-bottom: 28px;
}

.row {
  @include flex(flex-start, flex-start, column);

  & + & {
    margin-top: 45px;
  }

}

.theme-box {
  @include flex(flex-start, flex-start, column);
  width: 100%;

  & + & {
    margin-top: 45px;
  }

}

.pagination-wrap {
  margin-top: 50px;
}

@media screen and (min-width: $tablet) {
  .c-themes {
    background-color: $c-black;
    background-image: linear-gradient(rgba(34, 34, 34, 1) 1px, transparent 0),
      linear-gradient(90deg, rgba(34, 34, 34, 1) 1px, transparent 0);
    background-size: 20% 20%;
    padding-top: 90px;
    padding-bottom: 130px;
    background-attachment: fixed;

    // &__content {
    //   @include max-width(1366);
    // }
  }
  .year-wrap {
    width: 100%;
    @include flex(flex-end);
  }

  .row {
    @include flex();

    & + & {
      margin-top: 75px;
    }

    &--a {
      @include flex(space-between);
      height: 400px;
    }

    &--b {
      @include flex(center);
      position: relative;
      // left: -100px;
    }

    &--c {
      @include flex(space-between, flex-start);
      height: 400px;
    }

    &--d {
      @include flex(space-between);
      height: 400px;
    }
    &--e {
      @include flex(center);
    }
  }

  .theme-box {

    &--a-2 {
      @include flex(flex-end);
      // display: flex;
      align-self: flex-end;
    }

    &--b-1 {
      @include flex(center);
      // display: flex;
      // align-self: flex-end;
    }

    &--c-1 {
      display: flex;
      // align-self: flex-start;
    }

    &--c-2 {

      align-self: flex-end;
    }

    &--d-2 {
      @include flex(flex-end);
      align-self: flex-end;
    }

    &--e-1 {
      @include flex(center);
      // display: flex;
      // align-self: flex-end;
    }
  }
}

@media screen and (min-width: $sm-pc) {

  .row {
    @include flex();

    & + & {
      margin-top: 100px;
    }

    &--a {
      @include flex(space-between);
      height: 500px;
    }

    &--b {
      @include flex(center);
      position: relative;
      // left: -100px;
    }

    &--c {
      @include flex(space-between, flex-start);
      height: 500px;
    }

    &--d {
      @include flex(space-between);
      height: 500px;
    }
    &--e {
      @include flex(center);
    }
  }

}
</style>
