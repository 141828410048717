<template>
  <div class="explore-icon explore-icon--playlists">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g id="Group_4545" data-name="Group 4545" transform="translate(12444 -10775)">
        <rect
          id="Rectangle_1152"
          data-name="Rectangle 1152"
          width="30"
          height="30"
          transform="translate(-12444 10775)"
          :fill="color.main"
          opacity="0"
        />
        <g id="Group_4528" data-name="Group 4528" transform="translate(-5277.951 58.32)">
          <path
            id="Union_9"
            data-name="Union 9"
            d="M5279.39,3.139a1.1,1.1,0,1,1,0-2.195h14.685a2.4,2.4,0,0,1-1.055-1.91c.593.592,2.053,1.91,6.519,1.91h0a1.1,1.1,0,0,1,0,2.195Zm19.242-6.012V-13.52a.906.906,0,0,1,.906-.906.906.906,0,0,1,.906.906V-2.872a.906.906,0,0,1-.906.906A.906.906,0,0,1,5298.632-2.872Zm-20.34,0V-13.52a.906.906,0,0,1,.906-.906.906.906,0,0,1,.907.906V-2.872a.906.906,0,0,1-.907.906A.906.906,0,0,1,5278.292-2.872Zm11.172-1.736a3.588,3.588,0,0,1-3.588-3.586,3.545,3.545,0,0,1,3.588-3.586,3.587,3.587,0,0,1,3.586,3.586,3.585,3.585,0,0,1-3.585,3.586ZM5279.39-16.727a1.1,1.1,0,0,1-1.1-1.1,1.1,1.1,0,0,1,1.1-1.1c4.464,0,5.925-1.318,6.517-1.912a2.4,2.4,0,0,1-1.055,1.912h14.686a1.1,1.1,0,0,1,1.1,1.1,1.1,1.1,0,0,1-1.1,1.1Z"
            transform="translate(-12440.389 10740.514)"
            :fill="color.main"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import colorConfig from '@/components/Explore/Icon/colorConfig';

export default defineComponent({
  name: 'GenresIcon',
  props: {
    mainType: {
      type: String,
      default: '',
    },
    colorType: {
      type: String,
      default: 'hot',
    },
    currIcon: {
      type: String,
      default: '',
    },
    iconKey: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute();

    const color = computed(() => {
      // 僅 explore 頁面使用
      if (props.iconKey === route.params.type) {
        return colorConfig.exploreActive;
      }

      if (props.iconKey === props.currIcon) {
        return colorConfig[props.colorType];
      }

      return colorConfig[props.mainType];
    });

    return {
      color,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@/styles/share/exploreIcon.scss';
</style>
