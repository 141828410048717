<template>
  <div class="c-labels">

    <div class="popular popular--no-pb">
      <div
        :class="{
          'g-content': addGContent,
        }"
      >
        <popular-box-rwd-transform :data="mocklabelBoxs">
          <template v-slot:header>
            <explore-title title="精選品牌"/>
          </template>
          <template v-slot="labelsData">
            <labels-box color="white" :key="i" :data="labelsData.data" />
          </template>
        </popular-box-rwd-transform>
      </div>
    </div>

    <div class="c-labels__content g-content">
      <div class="alphabetical-box">
        <div>
          <ul class="alphabet-list">
            <li
              class="alphabet-list__item"
              :class="{ 'alphabet-list__item--active': alphabet === activeAlphabet }"
              v-for="alphabet of aToZ"
              :key="alphabet"
              @click="handleActive(alphabet)"
            >
              <p class="alphabet-list__item__text">
                {{ alphabet }}
              </p>
            </li>
          </ul>
        </div>

        <explore-title title="#">
          <template v-slot:right>
            <div class="select-wrap">
              <mi-select v-model:value="activeAlphabet" :options="aToZSelectedOptions" label="Name" />
            </div>
          </template>
        </explore-title>

        <ul class="more-label-box-list">
          <li class="more-label-box-list__item" v-for="item of mocklabelBoxs2" :key="item.title">
            <div class="more-label-box-list__item__box">
              <labels-box :data="item" />
            </div>
          </li>
        </ul>
      </div>

      <mi-search-pagination :pager="pager" @handlePagination="handlePagination" isWhiteBg/>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import LabelsBox from '@/components/Explore/LabelsBox.vue';
import ExploreTitle from '@/components/Explore/ExploreTitle.vue';
// import MiPagination from '@/components/Global/MiPagination.vue';
import MiSelect from '@/components/Global/Select/index.vue';
import img1 from '@/assets/explore/labels_img.png';
import img2 from '@/assets/explore/labels_img_2.png';
import img3 from '@/assets/explore/labels_img_3.png';
import img4 from '@/assets/explore/labels_img_4.png';
import PopularBoxRwdTransform from '@/components/Global/PopularBoxRwdTransform.vue';
import useAddGContent from '@/hooks/useAddGContent';
import MiSearchPagination from '@/components/Global/MiSearchPagination.vue';

export default defineComponent({
  name: 'Labels',
  components: {
    LabelsBox,
    ExploreTitle,
    // MiPagination,
    MiSelect,
    PopularBoxRwdTransform,
    MiSearchPagination,
  },
  setup() {
    const addGContent = useAddGContent();
    const router = useRouter();
    const route = useRoute();
    const activeAlphabet = ref('ALL');
    const aToZ = ['ALL'];

    const mocklabelBoxs = [
      {
        imgPath: img1,
        title: 'NIKE',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      },
      {
        imgPath: img2,
        title: 'ADDIDA',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      },
      {
        imgPath: img3,
        title: 'MIZUNO',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      },
      {
        imgPath: img4,
        title: 'UNDER ARMOUR',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      },
    ];

    const mocklabelBoxs2 = [
      {
        imgPath: img1,
        title: 'NIKE',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      },
      {
        imgPath: img2,
        title: 'ADDIDA',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      },
      {
        imgPath: img3,
        title: 'MIZUNO',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      },
      {
        imgPath: img4,
        title: 'UNDER ARMOUR',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      },
      {
        imgPath: img1,
        title: 'ASICS',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      },
    ];

    for (let i = 65; i <= 90; i += 1) {
      aToZ.push(String.fromCharCode(i));
    }

    const aToZSelectedOptions = aToZ.map((item) => ({ label: item, value: item }));

    const handleActive = (alphabet) => {
      activeAlphabet.value = alphabet;
    };

    const pager = reactive({
      pageIndex: route.query?.pageIndex || 1,
      totalPage: 2,
    });

    const handlePagination = (targetPage) => {
      pager.pageIndex = targetPage;

      router.push({
        name: 'explore-type',
        params: {
          type: 'labels',
        },
        query: {
          pageIndex: pager.pageIndex,
        },
      });
    };

    return {
      aToZ,
      activeAlphabet,
      handleActive,
      pager,
      handlePagination,
      mocklabelBoxs,
      mocklabelBoxs2,
      aToZSelectedOptions,
      addGContent,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/albumsAndPlaylists.scss';
@import '~@/styles/share/labels.scss';

.c-labels {
  padding-top: 32px;
  padding-bottom: 120px;
  background-color: $c-white3;
}

.alphabetical-box {
  margin-top: 100px;

  .label-box-list {
    margin: 0 -7.5px;

    &__item {
      margin-top: 26px;

      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 0px;
      }
    }
  }
}

.alphabet-list {
  display: none;
}

@media screen and (min-width: $tablet) {
  .c-labels {
    padding-top: 80px;
    padding-bottom: 120px;
  }

  .alphabet-list {
    width: 100%;
    @include flex(space-between);
    padding-bottom: 18px;
    border-bottom: 1px solid $c-assist4;
    margin-bottom: 100px;

    &__item {
      position: relative;
      cursor: pointer;

      &:hover {
        &::after {
          content: '';
          width: 100%;
          height: 6px;
          background-color: $c-main;
          display: inline-block;
          @include position(tl, calc(100% + 15px), 0);
        }

        .alphabet-list__item__text {
          color: $c-black;
        }
      }

      &--active {
        &::after {
          content: '';
          width: 100%;
          height: 6px;
          background-color: $c-main;
          display: inline-block;
          @include position(tl, calc(100% + 15px), 0);
        }

        .alphabet-list__item__text {
          color: $c-black;
        }
      }

      &__text {
        @include font-style($c-assist4, 20, bold);
      }
    }
  }

  // .more-label-box-list {
  //   &__item {
  //     width: 25%;

  //     &:nth-child(4) {
  //       margin-top: 0px;
  //     }
  //   }
  // }

  .select-wrap {
    display: none;
  }
}
</style>
