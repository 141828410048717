<template>
  <div class="c-album c-album-and-playlists">
    <div class="popular">
      <div
        :class="{
          'g-content': addGContent,
        }"
      >
        <popular-box-rwd-transform :data="popularAlbumMockData">
          <template v-slot:header>
            <explore-title title="精選專輯" />
          </template>
          <template v-slot="albumData">
            <albums-box-popular :key="i" :data="albumData.data" />
          </template>
        </popular-box-rwd-transform>
      </div>
    </div>

    <div class="normal">
      <div class="normal__content g-content">

        <normal-box-rwd-transform  :data="normalAlbumMockData">
          <template v-slot:header>
            <explore-title title="所有專輯" flexDirection="column">
              <template v-slot:extra>
                <SeeMore text="See all in search" :to="to" />
              </template>
              <template v-slot:right>
                <div class="select-wrap">
                  <mi-select v-model:value="sortValue" :options="mockOptions" label="Sort by" flexDirection="column" />
                  <mi-select
                    v-model:value="categoryValue"
                    :options="mockOptions"
                    label="Category"
                    flexDirection="column"
                  />
                </div>
              </template>
            </explore-title>
          </template>
          <template v-slot="albumData">
            <albums-box-normal color="white" :data="albumData.data" />
          </template>
        </normal-box-rwd-transform>
      </div>

      <mi-search-pagination :pager="pager" @handlePagination="handlePagination" isWhiteBg/>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AlbumsBoxPopular from '@/components/Explore/AlbumsBoxPopular.vue';
import AlbumsBoxNormal from '@/components/Explore/AlbumsBoxNormal.vue';
import ExploreTitle from '@/components/Explore/ExploreTitle.vue';
import SeeMore from '@/components/Global/SeeMore.vue';
import MiSelect from '@/components/Global/Select/index.vue';
// import MiPagination from '@/components/Global/MiPagination.vue';
import { popularAlbumMockData, normalAlbumMockData } from '@/config/mockData/albumsBox';
import PopularBoxRwdTransform from '@/components/Global/PopularBoxRwdTransform.vue';
import NormalBoxRwdTransform from '@/components/Global/NormalBoxRwdTransform.vue';
import useAddGContent from '@/hooks/useAddGContent';
import MiSearchPagination from '@/components/Global/MiSearchPagination.vue';

export default defineComponent({
  name: 'albums',
  components: {
    AlbumsBoxPopular,
    AlbumsBoxNormal,
    ExploreTitle,
    SeeMore,
    MiSelect,
    // MiPagination,
    PopularBoxRwdTransform,
    NormalBoxRwdTransform,
    MiSearchPagination,
  },
  setup() {
    // const siteStore = useSiteStore();
    const router = useRouter();
    const route = useRoute();
    const addGContent = useAddGContent();
    const sortValue = ref('name');
    const categoryValue = ref('newest');

    const mockOptions = [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'Newest',
        value: 'newest',
      },
      {
        label: 'Oldest',
        value: 'oldest',
      },
    ];

    const to = {
      name: 'search',
      query: {
        tab: 'albums',
      },
    };

    const pager = ref({
      pageIndex: route.query?.pageIndex || 1,
      totalPage: 6,
    });

    const handlePagination = (targetPage) => {
      pager.value.pageIndex = targetPage;

      router.push({
        name: 'explore-type',
        params: {
          type: 'themes',
        },
        query: {
          pageIndex: pager.value.pageIndex,
        },
      });
    };

    return {
      mockOptions,
      sortValue,
      categoryValue,
      to,
      pager,
      handlePagination,
      popularAlbumMockData,
      normalAlbumMockData,
      addGContent,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/albumsAndPlaylists.scss';
</style>
