import { print } from 'graphql/language/printer';
import {
  isArray, get, cloneDeep, set, isString,
} from 'lodash-es';
import restfulRequest from '@/api/axios';

const judgeAxios = ({
  urlOrRequest, descriptor, urlPrefix, tokenHeader, getToken, errorHandler,
}) => {
  if (typeof urlOrRequest === 'function') return urlOrRequest;
  if (isString(urlOrRequest)) {
    const request = restfulRequest?.create({
      baseURL: urlOrRequest,
      descriptor,
      urlPrefix,
      version: '',
      tokenHeader,
      getToken,
      errorHandler,
    });

    return async (requestData = {}) => {
      const [error, res] = await request(requestData);

      //  graphql errorCode, response.stauts === 200
      if (error === null && res) {
        const { data, errors } = res;
        if (errors) return [new Error(errors[0].message), null];
        return [null, data];
      }

      return [error, res];
    };
  }
  return () => {

  };
};

const formatDataByFile = (query, variables = {}, filePath = '') => {
  if (!filePath) return { query: print(query), variables };
  const originFileList = get(variables, filePath);
  const multiple = isArray(originFileList);
  if (!originFileList) {
    return null;
  }
  const form = new FormData();
  const fileList = multiple ? cloneDeep(originFileList) : [cloneDeep(originFileList)];

  // 把 variables 中放置檔案的字段值為 null
  if (multiple) originFileList.fill(null);
  else set(variables, filePath, null);

  // operations
  form.append('operations', JSON.stringify({ query: print(query), variables }));

  const { map, list } = fileList.reduce(
    (acc, { originFileObj }, idx) => {
      const { map: accMap, list: accList } = acc;
      return {
        map: { ...accMap, [idx]: multiple ? [`variables.${filePath}.${idx}`] : [`variables.${filePath}`] },
        list: [...accList, originFileObj],
      };
    },
    { map: {}, list: [] },
  );
  // map
  form.append('map', JSON.stringify(map));
  // file
  list.forEach((file, idx) => {
    form.append(idx, file);
  });
  return form;
};

function graphqlRequest() {}

graphqlRequest.create = ({
  baseURL: urlOrRequest = '',
  // 加密用，順便來判定是否需要加密
  descriptor = null,
  urlPrefix = '',
  // 獲取 token 的方法
  auth = true,
  tokenHeader = 'Bearer',
  getToken = null,
  // 錯誤處理
  errorHandler = null,
} = {}) => {
  const request = judgeAxios({
    urlOrRequest,
    descriptor,
    urlPrefix,
    auth,
    tokenHeader,
    getToken,
    errorHandler,
  });
  return (query, variables = {}, filePath = '') => {
    const data = formatDataByFile(query, variables, filePath);

    return request({
      method: 'POST',
      data,
    });
  };
};
export default graphqlRequest;
